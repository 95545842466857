/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Layout } from "../components";
import { StaticQuery, graphql, Link, navigate } from "gatsby";
import * as queryString from "query-string";

const Tile = ({ node }) => (
    <div className="col-md-6 case-col">
        <Link to={`/case-study/${node.slug}`}>
            <div className="post-wrapper">
                <div className="post-wrapper__thumbnail-container thumbnail-container">
                    <div className="rectangle"></div>
                    <img
                        className="img-fluid thumbnail-container__image image"
                        src={node.acf.acf_featured_img.source_url}
                        alt={node.title}
                    />
                </div>
                <p className="post-wrapper__category">
                    {Object.keys(node.acf.case_category).map(
                        ([key], index, arr) => (
                            <span key={index}>
                                {node.acf.case_category[key] ===
                                "produktyInwestycyjne"
                                    ? "Produkty inwestycyjne"
                                    : node.acf.case_category[key] ===
                                      "kampanieDeweloperskie"
                                    ? "Kampanie deweloperskie"
                                    : node.acf.case_category[key] ===
                                      "kampanieHotelowe"
                                    ? "Kampanie hotelowe"
                                    : node.acf.case_category[key] ===
                                      "kampanieZagraniczne"
                                    ? "Kampanie zagraniczne"
                                    : node.acf.case_category[key] ===
                                      "eCommerce"
                                    ? "E-commerce"
                                    : node.acf.case_category[key] ===
                                      "googleAds"
                                    ? "Google Ads"
                                    : node.acf.case_category[key] ===
                                      "gacebookAds"
                                    ? "Facebook Ads"
                                    : node.acf.case_category[key] ===
                                      "socialMedia"
                                    ? "Social media"
                                    : ""}
                                {index === arr.length - 1 ? "" : ", "}
                            </span>
                        )
                    )}
                </p>
                <div
                    className="post-wrapper__title"
                    dangerouslySetInnerHTML={{
                        __html: node.acf.custom_header
                            ? node.acf.custom_header
                            : node.title,
                    }}
                />
                <div className="text-wrapper--inner-action">
                    <span
                        to={`/case-study/${node.slug}`}
                        className="btn btn-line"
                    >
                        więcej
                    </span>
                </div>
            </div>
        </Link>
    </div>
);

const CaseStudy = ({
    caseStudyPosts,
    catProduktyInwestycyjne,
    catKampanieDeweloperskie,
    catKampanieZagraniczne,
    catKampanieHotelowe,
    catECommerce,
    catGoogleAds,
    catFacebookAds,
    catSocialMedia,
}) => {
    const isBrowser = typeof window !== "undefined";
    const search = isBrowser ? window.location.search : "";
    const location = isBrowser ? window.location : null;
    const params = new URLSearchParams(search);
    const [catParam, setCatParam] = useState(params.get("cat"));

    const [cat, setCat] = useState("all");

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setCat(Object.keys(parsed).length > 0 ? Object.keys(parsed)[0] : "all");
    }, []);

    return (
        <Layout
            seo={{
                title: "Case Studies",
                description:
                    "Odkryj historię sukcesów naszych klientów. Case study z wielu branż w obszarze Google Ads, Facebook Ads i social media. Win-win digital agency. Ekspercka wiedza, partnerska współpraca, imponujące wyniki. Wygrywaj z nami! #whenWEwin",
                href: "pl",
                lang: "pl",
            }}
        >
            <div className="subpage-header subpage-header--case-studies-page">
                <h1 className="subpage-header__title">
                    case <span>studies</span>
                </h1>
            </div>

            <section className="case-studies-main">
                <div className="container-fluid">
                    <div className="categories">
                        <p className="categories__title">Kategorie</p>
                        <div className="categories__row">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study/");
                                    setCat("all");
                                }}
                                className={cat === null ? "current" : ""}
                            >
                                Wszystkie
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate(
                                        "/case-study?produkty-inwestycyjne"
                                    );
                                    setCat("produkty-inwestycyjne");
                                }}
                                className={
                                    cat === "produkty-inwestycyjne"
                                        ? "current"
                                        : ""
                                }
                            >
                                Produkty inwestycyjne
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate(
                                        "/case-study?kampanie-deweloperskie"
                                    );
                                    setCat("kampanie-deweloperskie");
                                }}
                                className={
                                    cat === "kampanie-deweloperskie"
                                        ? "current"
                                        : ""
                                }
                            >
                                Kampanie deweloperskie
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study?kampanie-hotelowe");
                                    setCat("kampanie-hotelowe");
                                }}
                                className={
                                    cat === "kampanie-hotelowe" ? "current" : ""
                                }
                            >
                                Kampanie hotelowe
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate(
                                        "/case-study?kampanie-zagraniczne"
                                    );
                                    setCat("kampanie-zagraniczne");
                                }}
                                className={
                                    cat === "kampanie-zagraniczne"
                                        ? "current"
                                        : ""
                                }
                            >
                                Kampanie zagraniczne
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study?e-commerce");
                                    setCat("e-commerce");
                                }}
                                className={
                                    cat === "e-commerce" ? "current" : ""
                                }
                            >
                                E-commerce
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study?google-ads");
                                    setCat("google-ads");
                                }}
                                className={
                                    cat === "google-ads" ? "current" : ""
                                }
                            >
                                Google Ads
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study?facebook-ads");
                                    setCat("facebook-ads");
                                }}
                                className={
                                    cat === "facebook-ads" ? "current" : ""
                                }
                            >
                                Facebook Ads
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCatParam(null);
                                    navigate("/case-study?social-media");
                                    setCat("social-media");
                                }}
                                className={
                                    cat === "social-media" ? "current" : ""
                                }
                            >
                                Social media
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        {cat === "all"
                            ? caseStudyPosts.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "produkty-inwestycyjne"
                            ? catProduktyInwestycyjne.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "kampanie-deweloperskie"
                            ? catKampanieDeweloperskie.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "kampanie-hotelowe"
                            ? catKampanieHotelowe.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "kampanie-zagraniczne"
                            ? catKampanieZagraniczne.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "e-commerce"
                            ? catECommerce.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "google-ads"
                            ? catGoogleAds.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "facebook-ads"
                            ? catFacebookAds.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : cat === "social-media"
                            ? catSocialMedia.edges.map(({ node }) => (
                                  <Tile node={node} key={node.id} />
                              ))
                            : ""}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default () => (
    <StaticQuery
        query={graphql`
            {
                caseStudyPosts: allWordpressWpCasestudy(
                    sort: { order: DESC, fields: date }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catProduktyInwestycyjne: allWordpressWpCasestudy(
                    filter: {
                        acf: { case_category: { eq: "produktyInwestycyjne" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catKampanieDeweloperskie: allWordpressWpCasestudy(
                    filter: {
                        acf: { case_category: { eq: "kampanieDeweloperskie" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catKampanieHotelowe: allWordpressWpCasestudy(
                    filter: {
                        acf: { case_category: { eq: "kampanieHotelowe" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catKampanieZagraniczne: allWordpressWpCasestudy(
                    filter: {
                        acf: { case_category: { eq: "kampanieZagraniczne" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catECommerce: allWordpressWpCasestudy(
                    filter: { acf: { case_category: { eq: "eCommerce" } } }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catGoogleAds: allWordpressWpCasestudy(
                    filter: { acf: { case_category: { eq: "googleAds" } } }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catFacebookAds: allWordpressWpCasestudy(
                    filter: { acf: { case_category: { eq: "gacebookAds" } } }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
                catSocialMedia: allWordpressWpCasestudy(
                    filter: { acf: { case_category: { eq: "socialMedia" } } }
                ) {
                    edges {
                        node {
                            id
                            title
                            slug
                            acf {
                                case_category
                                acf_featured_img {
                                    source_url
                                }
                                custom_header
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <CaseStudy {...data} />}
    />
);
